import React, {Component} from 'react';
import {Input ,Typography} from "antd";
import { Avatar, Card } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import styled from "styled-components";
import {LogoutOutlined,} from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import StationImage from 'assets/images/Station.JPG'

const { Search } = Input;
function mapStateToProps(state) {
    return {};
}
const { Meta } = Card;
function renderComponent() {
  const ChargerID = ['1', '2', '3'];

  const list = ChargerID.map(ChargerID => <div><Divider orientation="left">Charger ID: {ChargerID} </Divider>
                                <Row>
                                  <Col span={6} xs={{ order: 4 }} sm={{ order: 3 }} md={{ order: 2 }} lg={{ order: 1 }}>
                                 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                 <Card
                                          style={{ width: 300 }}
                                          cover={
                                          <img
                                          alt="Station"
                                          src={StationImage}
                                          />
                                          }
                                          actions={[
                                              <PlayCircleOutlined key="remoteStart" />,
                                              <StopOutlined key="remoteStop" />,
                                              <SettingOutlined key="setting" />,
                                              <EditOutlined key="edit" />,
                                              <EllipsisOutlined key="ellipsis" />,
                                              ]}
                                          >
                                          <Meta
                                              avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                              title="Connector ID: Connector 1"
                                              description="Available"
                                              />
                                      </Card>
                                      </div>
                                  </Col>
                                  <Col span={6} xs={{ order: 4 }} sm={{ order: 3 }} md={{ order: 2 }} lg={{ order: 1 }}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                       <Card
                                          style={{ width: 300 }}
                                          cover={
                                          <img
                                          alt="Station"
                                          src={StationImage}
                                          />
                                          }
                                          actions={[
                                              <PlayCircleOutlined key="remoteStart" />,
                                              <StopOutlined key="remoteStop" />,
                                              <SettingOutlined key="setting" />,
                                              <EditOutlined key="edit" />,
                                              <EllipsisOutlined key="ellipsis" />,
                                              ]}
                                          >
                                          <Meta
                                              avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                              title="Connector ID: Connector 2"
                                              description="Available"
                                              />
                                      </Card>
                                </div>
                                  </Col>
                                  <Col span={6} xs={{ order: 4 }} sm={{ order: 3 }} md={{ order: 2 }} lg={{ order: 1 }}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                      <Card
                                          style={{ width: 300 }}
                                          cover={
                                          <img
                                          alt="Station"
                                          src={StationImage}
                                          />
                                          }
                                          actions={[
                                              <PlayCircleOutlined key="remoteStart" />,
                                              <StopOutlined key="remoteStop" />,
                                              <SettingOutlined key="setting" />,
                                              <EditOutlined key="edit" />,
                                              <EllipsisOutlined key="ellipsis" />,
                                              ]}
                                          >
                                          <Meta
                                              avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                              title="Connector ID: Connector 3"
                                              description="Available"
                                              />
                                      </Card>
                                </div>
                                  </Col>
                                  <Col span={6} xs={{ order: 4 }} sm={{ order: 3 }} md={{ order: 2 }} lg={{ order: 1 }}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                      <Card
                                          style={{ width: 300, }}
                                          cover={
                                          <img
                                          alt="Station"
                                          src={StationImage}
                                          />
                                          }
                                          actions={[
                                              <PlayCircleOutlined key="remoteStart" />,
                                              <StopOutlined key="remoteStop" />,
                                              <SettingOutlined key="setting" />,
                                              <EditOutlined key="edit" />,
                                              <EllipsisOutlined key="ellipsis" />,
                                              ]}
                                          >
                                          <Meta
                                              avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                              title="Connector ID: Connector 4"
                                              description="Available"
                                              />
                                      </Card>
                                </div>
                                  </Col>
                                </Row>
        </div>
  )
  return (
    <div>
      {list}
    </div>
  )
}
class OCPP201 extends Component {
    render() {
        return (
            <div>
                <Typography.Title>OCPP Version 2.0.1</Typography.Title>
                <br />
                <Search placeholder="input search text" enterButton="Search" size="large" loading />
                <br />
                {renderComponent()}
            </div>
        );
    }
}

export default OCPP201;