import React, {Fragment, useState, useEffect} from 'react';
import styled from "styled-components";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {App, Button, Col, DatePicker, Divider, Form, Input, message, Row, Select, Space, Typography} from "antd";
import {useLoaderData, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";

const UserAdd = () => {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isSubmiting, setSubmitting] = useState();
    const [mode, setMode] = useState();
    const [btnSaveLabel, setBtnSaveLabel] = useState();
    const [headerLabel, setHeaderLabel] = useState();
    const { username } = useParams(); //***
    const {notification, modal} = App.useApp();
    const initForm = useLoaderData();  //***

    useEffect(() => {
        console.log("USERAdd : Enter useEffect");
        //initAllDropDownList();
        if(username!=null && username!=""){
            console.log("username="+username);
            setMode("edit");
            setBtnSaveLabel("Update");
            setHeaderLabel("Edit USER");
            initData();
        }else{
            console.log("username not found");
            setMode("new");
            setBtnSaveLabel("Add");
            setHeaderLabel("New User");
        }
    }, []);
    const initData = async () => {
        console.log("USERAdd : Enter initFormData");
        try {
            console.log(initForm);
            form.setFieldsValue({
                "username": initForm.username,
                "firstName": initForm.firstName,
                "middleName": initForm.middleName,
                "lastName": initForm.lastName,
                "birthday": initForm.birthday,
            })
        } catch (e) {
             console.log(e);
        }
    };
    const gotoLogin = () => {
        navigate("..", {replace: true, relative: "path"})
    }

    const onFinish = async (values) => {
        try {
            setSubmitting(true);
            await axios.post("/api/v1/auth/register", values);
            form.resetFields();
            modal.success({
                title: 'Sign up successfully',
                content: 'your username is ready, going to login page',
                onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "Sign up Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <Fragment>
        <Row justify="start" align="middle" gutter={8}>
            <Col style={{ width:'30%' }} ><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></Col>
            <Col style={{ width:'28%' }} align="middle" ><Typography.Title level={4} style={{ margin: 16 }}>{headerLabel}</Typography.Title></Col>
            <Col style={{ width:'35%' }} ></Col>
        </Row>
        <FormStyled
            name="basic"
            form={form}
            disabled={isSubmiting}
            style={{width: 'minmax(400px, 90%)'}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Divider>Profile</Divider>
            <Row gutter={[16, 16]}>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="First name"
                        name="firstName"
                        labelCol={{  span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First name'
                            }
                        ]}
                    >
                        <Input placeholder="Enter First name"/>
                    </Form.Item>
                </Col>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="Middle name"
                        name="middleName"
                        labelCol={{  span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Middle name'
                            }
                        ]}
                    >
                        <Input placeholder="Enter Middle name"/>
                    </Form.Item>
                </Col>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="Last name"
                        name="lastName"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Last name',
                            },
                        ]}
                    >
                        <Input placeholder ="Enter Last name"/>
                    </Form.Item>
                </Col>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="Birthday"
                        name="birthday"
                        labelCol={{  span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Birthday',
                            },
                        ]}
                    >
                        <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                </Col>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="Sex"
                        name="Sex"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your sex',
                            },
                        ]}
                    >
                        <Select  placeholder="Select your sex" optionFilterProp="children" onChange={onChange} options={[
                            {
                                value: 'Male',
                                label: 'M',
                            },
                            {
                                value: 'Female',
                                label: 'F',
                            },
                            {
                                value: 'Other',
                                label: 'Other',
                            },
                        ]}
                        />
                    </Form.Item>
                </Col>
                <Col  span={24} lg={12}>
                    <Form.Item
                        label="Phone Number"
                        name="Phone Number"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number',
                            },
                        ]}
                    >
                        <Input placeholder ="Enter Phone Number"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Additional Note"
                        name="AdditionalNote"
                        labelCol={{ span: 24, lg: 5, xl: 4 }}
                        rules={[
                            {
                                required: false,
                                message: ' ',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider>Address</Divider>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Street"
                        name="Street"
                        labelCol={{ span: 24, lg: 5, xl: 4 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your street'
                            }
                        ]}
                    >
                        <Input placeholder="Enter Street"/>
                    </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                    <Form.Item
                        label="House Number"
                        name="House Number"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: false,
                                message: 'Please input your house number'
                            }
                        ]}
                    >
                        <Input placeholder="Enter house number"/>
                    </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                    <Form.Item
                        label="Zip code"
                        name="zip_code"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: false,
                                message: 'Please input your zip code'
                            }
                        ]}
                    >
                        <Input placeholder="Enter zip code"/>
                    </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                    <Form.Item
                        label="City"
                        name="city"
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        rules={[
                            {
                                required: false,
                                message: 'Please input your city'
                            }
                        ]}
                    >
                        <Input placeholder="Enter city"/>
                    </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                    <Form.Item
                        labelCol={{ span: 24, lg: 10, xl: 8 }}
                        label="Country"
                        name="Country"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your country',
                            },
                        ]}
                    >
                        <Select  placeholder="Select a country" optionFilterProp="children" onChange={onChange} options={[
                            {
                                value: 'TH',
                                label: 'Thailand',
                            },
                            {
                                value: 'MA',
                                label: 'Malaysia',
                            },
                            {
                                value: 'ID',
                                label: 'Indonesia',
                            },
                        ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider>RFID</Divider>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        labelCol={{ span: 24, lg: 5, xl: 4 }}
                        label="RFID TAG"
                        name="RFID TAG"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your RFID TAG',
                            },
                        ]}
                    >
                        <Select  placeholder="Select a RFID" optionFilterProp="children" onChange={onChange} options={[
                            {
                                value: '1',
                                label: '1',
                            },
                            {
                                value: '2',
                                label: '2',
                            },
                            {
                                value: '3',
                                label: '3',
                            },
                        ]}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <FormAction wrapperCol={{ offset: 7, span: 16 }}>

                        <Space>
                            <Button type="primary" htmlType="submit" style={{width: 120}} loading={isSubmiting}>
                                {btnSaveLabel}
                            </Button>
                        <Button type="primary" htmlType="back" style={{width: 120}} onClick={() => navigate(-1)}>
                                Back
                        </Button>
                    </Space>

            </FormAction>
        </FormStyled>
        </Fragment>
    );
};

export default UserAdd;

const FormStyled = styled(Form)`
  width: 800px;
  max-width: 98%;
  margin: 0 auto;
  background: #00000088;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
  }
`;


const handleButtonClick = (e) => {
  message.info('Click on left button.');
  console.log('click left button', e);
};

const handleMenuClick = (e) => {
  message.info('Click on menu item.');
  console.log('click', e);
};
const onChange = (date, dateString) => {
  console.log(date, dateString);
};
