import React, { useEffect, useState} from 'react';
import {App,Typography, Space, Table, Tag, Col, Button, Row, Input} from "antd";
import {PlusOutlined, AudioOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import type { SearchProps } from '../Search';
import {Link} from "react-router-dom";
import axios from "axios";

const { Column } = Table;
const { Search } = Input;
const onSearch = (value, _e, info) => console.log(info?.source, value);

const suffix = (

  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);

const RfidList = () => {

    const onDelete = async (values) => {
        console.log("=== RFID Delete : onDelete ===");
        //console.log(values);

        const deleteData = {
            idTag: values.idTag,
         };
        console.log(deleteData);

        try {

            //await axios.post("/api/v1/rfid/register", values);
            await axios.delete("/api/v1/rfid/deleteByIdTag/"+values.idTag);
            //await axios.post("/api/v1/rfid/deleteByIdTag/"+values.idTag);

            modal.success({
                title: ' RFID delete successfully',
                content: 'RFID delete successfully',
                //onOk: gotoLogin
            })
            onSearchAll();
        } catch (e) {
            notification.error({
                message: "Delete RFID Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            console.log("=== RFID delete : onDelete : finally ===");
        }
    };

    const rfidColumns = [
          {
            title: 'No.',
            dataIndex: 'ocppTagPk',
            key: 'ocppTagPk',
          },
          {
            title: 'RFID Number',
            dataIndex: 'idTag',
            key: 'idTag',
          },
          {
            title: 'Expire Date',
            dataIndex: 'expiryDateDisplay',
            key: 'expiryDateDisplay',
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">
                <Link to={''+record.ocppTagPk} ><Button type="link"><EditOutlined/></Button></Link>
                <a onClick={() => onDelete(record)}><DeleteOutlined/></a>
              </Space>
            ),
          },
    ];

    const [rfidDataList, setRfidDataList] = useState([]);
    const {notification, modal} = App.useApp();

    useEffect(() => {
        console.log("ChargerList : Enter useEffect : init search");
        onSearchAll();
    }, []);

    const onSearchAll = async () => {
        console.log("RfidList : Enter onSearchAll");
        try {
            //axios.get("/api/v1/rfid/rfidtags").then((response) => {
            axios.get("/api/v1/rfid/rfidcards").then((response) => {
               console.log(response.data);
               setRfidDataList( response.data );
            });
        } catch (e) {
             console.log(e);
        }
    };

    return (
        <div>
                <Row justify="start" align="middle" gutter={8}>

                    <Col style={{ width:'25%' }}>
                        <Typography.Title level={4} style={{ margin: 16 }}>Add RFID</Typography.Title>
                    </Col>

                    <Col style={{ width:'50%' }}>
                        <form><Search placeholder="input search text" enterButton="Search" suffix={suffix} onSearch={onSearch}/></form>
                    </Col>

                    <Col style={{ width:'25%' }} align="right">
                        <Link to="add"><Button type="primary" icon={<PlusOutlined />} >RFID Add</Button> </Link>
                    </Col>

                </Row>

            <Table columns={rfidColumns} dataSource={rfidDataList} />

        </div>
    );
};

export default RfidList;