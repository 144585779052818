import {createBrowserRouter} from 'react-router-dom';
import Login from 'components/Login'
import AuthLayout from "../components/AuthLayout";
import Register from "../components/register/Register";
import ForgetPassword from "../components/forget_password/ForgetPassword";
import PrivacyPolicy from "../components/PrivacyPolicy";


export default createBrowserRouter([
        {
            id: "root", path: "/", element: <AuthLayout/>,
            children: [
                {index: true, element: <Login/>},
                {path: "signup", element: <Register/>},
                {path: "forget_password", element: <ForgetPassword/>},
                {path: "privacy-policy", element: <PrivacyPolicy/>}
            ]
        },
    ]
);