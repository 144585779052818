import React from 'react';
import {Typography} from "antd";
import data from 'components/logs/application.log';
import { LazyLog } from "react-lazylog";
import Line from 'react-lazylog/build/Line';


const Logs = () => {
const url =
    "https://gist.githubusercontent.com/helfi92/96d4444aa0ed46c5f9060a789d316100/raw/ba0d30a9877ea5cc23c7afcd44505dbc2bab1538/typical-live_backing.log";

    return (
        <div>
            <Typography.Title>Logs</Typography.Title>
            <div style={{ height: 1000, width: "100%" }}>
            <LazyLog extraLines={1} enableSearch url={data} caseInsensitive stream/>
            </div>
        </div>
    );
};

export default Logs;