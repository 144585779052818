import React, {useEffect, useState} from 'react';
import {App, Button, Col, Input, Row, Space, Table, Tag, Typography} from "antd";
import {AudioOutlined, PlusOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import axios from "axios";

const { Search } = Input;
const onSearch = (value, _e, info) => console.log(info?.source, value);
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);


const ChargerList = () => {

    const chargeBoxColumns = [
      {
        title: 'Charger ID',
        dataIndex: 'chargeBoxId',
        key: 'chargeBoxId',
        //render: (text) => <a>{text}</a>,
      },
      {
        title: 'Location',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'Status',
        dataIndex: 'registrationStatus',
        key: 'registrationStatus',
      },
      {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, { tags }) => (
          <>
            {tags.map((tag) => {
              let color = 'green'; // 'geekblue' : 'green' : 'volcano';
              if (tag === 'register') {
                color = 'geekblue';
              }else if(tag === 'offline'){
                color = 'volcano';
              }else if(tag === 'reject'){
                color = 'geekblue';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Link to={record.chargeBoxId}><Button type="link"><EditOutlined /></Button></Link>
            <a onClick={() => onDelete(record)}><DeleteOutlined /></a>
          </Space>
        ),
      },
    ];

    const [chargeBoxDataList, setChargeBoxDataList] = useState([]);

    useEffect(() => {
        console.log("ChargerList : Enter useEffect : init search");
        onSearchAll();
    }, []);

    const onSearchAll = async () => {
        console.log("ChargerList : Enter onSearchAll");
        try {
            const response = await axios.get("/api/v1/chargebox/chargeboxs");
            setChargeBoxDataList( response.data );
        } catch (e) {
            console.log(e);
        }
    };

    const {notification, modal} = App.useApp();
        const onDelete = async (values) => {
                console.log("=== User Delete : onDelete ===");
                //console.log(values);

                const deleteData = {
                    chargeBoxId: values.chargeBoxId,
                 };
                console.log(deleteData);

                try {
                    await axios.delete("/api/v1/chargebox/deleteChargeBoxId/"+values.chargeBoxId);
                    modal.success({
                        title: ' charge Box Id delete successfully',
                        content: values.chargeBoxId+' delete successfully',
                        //onOk: gotoLogin
                    })
                    onSearchAll();
                } catch (e) {
                    notification.error({
                        message: "Delete Charge Box ID Error",
                        description: e.response?.data?.message || "Something went wrong, please try again later"
                    })
                } finally {
                    console.log("=== Charge Box ID delete : onDelete : finally ===");
                }
        };
    return (
            <div>
                <Row justify="start" align="middle" gutter={8}>
                    <Col style={{ width:'25%' }}>
                        <Typography.Title level={4} style={{ margin: 16 }}>Charger List </Typography.Title>
                    </Col>
                    <Col style={{ width:'50%' }}>
                        <form><Search placeholder="input search text" enterButton="Search" suffix={suffix} onSearch={onSearch}/></form>
                    </Col>
                    <Col style={{ width:'25%' }} align="right">
                        <Link to="add"><Button type="primary" icon={<PlusOutlined />} >Charger Add</Button> </Link>
                    </Col>
                </Row>

                <Table columns={chargeBoxColumns} dataSource={chargeBoxDataList} />
            </div>
    );
};

export default ChargerList;