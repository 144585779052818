import React, {useState} from 'react';
import styled from "styled-components";
import {App, Button, Divider, Form, Input, Typography} from "antd";
import {useNavigate} from 'react-router-dom';
import axios from "axios";

const Register = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isSubmiting, setSubmitting] = useState();
    const {notification, modal} = App.useApp();
    const gotoLogin = () => {
        navigate("..", {replace: true, relative: "path"})
    }
    const onFinish = async (values) => {
        try {
            setSubmitting(true);
            await axios.post("/api/v1/auth/register", values);
            form.resetFields();
            modal.success({
                title: 'Sign up successfully',
                content: 'your username is ready, going to login page',
                onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "Sign up Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
        }


    };

    return (
        <FormStyled
            name="basic"
            form={form}
            disabled={isSubmiting}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            style={{width: 'minmax(400px, 90%)'}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Typography.Title style={{textAlign: 'center', marginTop: 0, marginBottom: 40}}>Sign up new
                user</Typography.Title>
            <Form.Item
                label="Email"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!'
                    }
                ]}
            >
                <Input placeholder="Enter Email"/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder="Enter Password"/>
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: 'Please input confirm password!',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Enter Confirm Password"/>
            </Form.Item>
            <Divider>Personal Information</Divider>
            <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                    {
                        required: true,
                        message: 'First name is required.',
                    },
                ]}
            >
                <Input placeholder="Enter First Name"/>
            </Form.Item>
            <Form.Item
                label="Middle Name"
                name="middlename"
            >
                <Input placeholder="Enter Middle Name"/>
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                    {
                        required: true,
                        message: 'Last name is required.',
                    },
                ]}
            >
                <Input placeholder="Enter Last Name"/>
            </Form.Item>
            <FormAction
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit" style={{width: 120}} loading={isSubmiting}>
                    Sign up
                </Button>
                <Button type="link" onClick={gotoLogin}>
                    Have an account, go to login.
                </Button>
            </FormAction>
        </FormStyled>
    );
};

export default Register;

const FormStyled = styled(Form)`
  min-width: 620px;
  width: 90%;
  max-width: 600px;
  background: #00000088;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
`;
const GoogleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
