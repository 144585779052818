import {theme} from "antd";

const themeConfig = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: "#aa7bec",
        colorInfo: "#aa7bec",
        colorSuccess: "#75fd31"
    },
    components: {
        Menu: {
            itemBorderRadius: 0
        }
    }
}

export default themeConfig;