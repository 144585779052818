import {useContext} from "react";
import {App as AntApp, ConfigProvider} from 'antd';
import {RouterProvider} from "react-router-dom";
import themeConfig from 'theme-config'
import AuthProvider, {AuthContext} from './AuthContext'
import Loading from "./Loading";

function App() {
    return (
        <ConfigProvider theme={themeConfig}>
            <AntApp>
                <AuthProvider>
                    <AppRouter/>
                </AuthProvider>
            </AntApp>
        </ConfigProvider>
    );
}


function AppRouter() {
    const {router, isReady} = useContext(AuthContext);
    if (!isReady) return <Loading/>;
    return <RouterProvider router={router}/>;
}

export default App;
