import axios from "axios";
import {extractUser} from "./AuthContext";

axios.interceptors.request.use(config => {
    const token = localStorage.getItem("_t");

    if (token) {
        const user = extractUser(token);
        if (user) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return config;
});