import React from 'react';
import {Typography} from "antd";

const NotificationEmail = () => {
    return (
        <div>
            <Typography.Title>Notification Email</Typography.Title>
        </div>
    );
};

export default NotificationEmail;