import React from 'react';
import {Typography} from "antd";
import { useEffect, useState } from 'react'
import styled from "styled-components";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import { ResponsivePieCanvas } from '@nivo/pie'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { Col, Divider, Row, Timeline, Button } from 'antd';
import {Space, Table, Tag} from "antd";
import evStationA from 'assets/images/evStation-a.png'
import evStationB from 'assets/images/evStation-b.png'
import evStationC from 'assets/images/evStation-c.png'
import evStationD from 'assets/images/evStation-d.png'
import { ToTopOutlined, MenuUnfoldOutlined, RightOutlined, Tooltip, Progress, Upload, Radio} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { ResponsiveLine } from '@nivo/line'
import axios from "axios";
const Dashboard = () => {

const [chargerDashboardsCountStatusDataList, setChargerDashboardsCountStatusDataList] = useState([]);
useEffect(() => {
    console.log("chargerDashboardsCountStatusList : Enter useEffect : init search");
    onSearchChargerDashboardsCountStatus();
}, []);

const onSearchChargerDashboardsCountStatus = async () => {
    console.log("chargerDashboardsCountStatusList : Enter onSearchChargerDashboardsCountStatus");
    try {
        axios.get("/api/v1/chargebox/chargerDashboardsCountStatus").then((response) => {
           console.log(response.data);
           setChargerDashboardsCountStatusDataList( response.data );
        });
    } catch (e) {
         console.log(e);
    }
};


const ChargerDashboardsCountStatusColumns = [

  {
    dataIndex: 'chargerOnline',
    key: 'chargerOnline',
    today: "Charger Online",
    title: <div>{chargerDashboardsCountStatusDataList.chargerOnline} Charger</div>,
    icon: <img alt="Station" src={evStationB} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'chargerOffline',
    key: 'chargerOffline',
    today: "Charger Offline",
    title: <div>{chargerDashboardsCountStatusDataList.chargerOffline} Charger</div>,
    icon: <img alt="Station" src={evStationA} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'chargerReject',
    key: 'chargerReject',
    today: "Charger Reject",
    title: <div>{chargerDashboardsCountStatusDataList.chargerReject} Charger</div>,
    icon: <img alt="Station" src={evStationB} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorOffline',
    key: 'chargerAll',
    today: "All Charger",
    title: <div>{chargerDashboardsCountStatusDataList.chargerAll} Charger</div>,
    icon: <img alt="Station" src={evStationA} width="55px" height="60px" />,
    bnb: "bnb2"
  }
];

const CountChargerDashboardsCountStatusColumns = [

  {
    dataIndex: 'connectorAvailable',
    key: 'connectorAvailable',
    today: "Connector Available",
    title: <div>{chargerDashboardsCountStatusDataList.connectorAvailable}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationB} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorPreparing',
    key: 'connectorPreparing',
    today: "Connector Preparing",
    title: <div>{chargerDashboardsCountStatusDataList.connectorPreparing}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationA} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorCharging',
    key: 'connectorCharging',
    today: "Connector Charging",
    title: <div>{chargerDashboardsCountStatusDataList.connectorCharging}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationA} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorFinishing',
    key: 'connectorFinishing',
    today: "Connector Finishing",
    title: <div>{chargerDashboardsCountStatusDataList.connectorFinishing}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationB} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorOffline',
    key: 'connectorOffline',
    today: "Connector Offline",
    title: <div>{chargerDashboardsCountStatusDataList.connectorOffline}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationC} width="55px" height="60px" />,
    bnb: "bnb2"
  },
  {
    dataIndex: 'connectorFaulted',
    key: 'connectorFaulted',
    today: "Connector Faulted",
    title: <div>{chargerDashboardsCountStatusDataList.connectorFaulted}/{chargerDashboardsCountStatusDataList.connectorAll} Connector</div>,
    icon: <img alt="Station" src={evStationD} width="55px" height="60px" />,
    bnb: "bnb2"
  }
];


const [ConnectorDataList, setConnectorDataList] = useState([]);
useEffect(() => {
    console.log("ConnectorList : Enter useEffect : init search");
    onSearchConnector();
}, []);

const onSearchConnector = async () => {
    console.log("ConnectorList : Enter onSearchIncome");
    try {
        axios.get("/api/v1/chargerdashboard/chargerdashboards").then((response) => {
           console.log(response.data);
           setConnectorDataList( response.data );
        });
    } catch (e) {
         console.log(e);
    }
};

const ConnectorColumns = [
  {
    title: 'Connector Number',
    dataIndex: 'connectorId',
    key: 'connectorId',
  },
  {
    title: 'Charger Name',
    dataIndex: 'chargerBoxId',
    key: 'chargerBoxId',
  },
  {
    title: 'OCPP Version',
    dataIndex: 'ocppProtocol',
    key: 'ocppProtocol',
  },
  {
    title: 'Charger Vendor',
    dataIndex: 'chargePointVendor',
    key: 'chargePointVendor',
  },
  {
    title: 'Charger Model',
    dataIndex: 'chargePointModel',
    key: 'chargePointModel',
  },
  {
    title: 'Street',
    dataIndex: 'street',
    key: 'street',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Latitude',
    dataIndex: 'locationLatitude',
    key: 'locationLatitude',
  },
  {
    title: 'Longitude',
    dataIndex: 'locationLongitude',
    key: 'locationLongitude',
  },

];

const billingColumns = [

  {
    title: 'User',
    dataIndex: 'idTag',
    key: 'idTag',
  },
  {
    title: 'Charger Name',
    dataIndex: 'chargeBoxId',
    key: 'chargeBoxId',
  },
  {
    title: 'Connector ID',
    dataIndex: 'connectorId',
    key: 'connectorId',
  },
  {
    title: 'Start Time',
    dataIndex: 'startTimestampDisplay',
    key: 'startTimestampDisplay',
  },
  {
    title: 'Stop Time',
    dataIndex: 'stopTimestampDisplay',
    key: 'stopTimestampDisplay',
  },
  {
    title: 'Charge Time',
    dataIndex: 'chargeTimeDisplay',
    key: 'chargeTimeDisplay',
  },
  {
    title: 'Energy (kWh)',
    dataIndex: 'calculateMeterValue',
    key: 'calculateMeterValue',
  },
  {
    title: 'Charge Price (THB)',
    dataIndex: 'twoDigitChargePrice',
    key: 'twoDigitChargePrice',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Link to={record.idTag} ><Button type="link">Print Billing</Button></Link>
      </Space>
    ),
  },
];

const [billingDataList, setBillingDataList] = useState([]);
useEffect(() => {
    console.log("BillingList : Enter useEffect : init search");
    onSearchIncome();
}, []);

const onSearchIncome = async () => {
    console.log("BillingList : Enter onSearchIncome");
    try {
        axios.get("/api/v1/billing/billings").then((response) => {
           console.log(response.data);
           setBillingDataList( response.data );
        });
    } catch (e) {
         console.log(e);
    }
};

const pieData=[
          {id: "Online", value: "12"},
          {id: "Offline", value: "3"},
          {id: "Error", value: "1"}
          ];
const lineData=[
                 {
                   "id": "Connector 1",
                   "color": "hsl(329, 70%, 50%)",
                   "data": [
                     {
                       "x": "08:00",
                       "y": 5
                     },
                     {
                       "x": "09:00",
                       "y": 7.4
                     },
                     {
                       "x": "10:00",
                       "y": 15
                     },
                     {
                       "x": "11:00",
                       "y": 18
                     },
                     {
                       "x": "12:00",
                       "y": 20
                     },
                     {
                       "x": "13:00",
                       "y": 22
                     },
                     {
                       "x": "14:00",
                       "y": 22
                     },
                     {
                       "x": "15:00",
                       "y": 22
                     },
                     {
                       "x": "16:00",
                       "y": 18
                     },
                     {
                       "x": "17:00",
                       "y": 7.4
                     },
                     {
                       "x": "18:00",
                       "y": 7
                     },
                     {
                       "x": "19:00",
                       "y": 2
                     }
                   ]
                 },
                 {
                   "id": "Connector 2",
                   "color": "hsl(38, 70%, 50%)",
                   "data": [
                     {
                       "x": "08:00",
                       "y": 7
                     },
                     {
                       "x": "09:00",
                       "y": 15
                     },
                     {
                       "x": "10:00",
                       "y": 18
                     },
                     {
                       "x": "11:00",
                       "y": 18
                     },
                     {
                       "x": "12:00",
                       "y": 22
                     },
                     {
                       "x": "13:00",
                       "y": 22
                     },
                     {
                       "x": "14:00",
                       "y": 12
                     },
                     {
                       "x": "15:00",
                       "y": 12
                     },
                     {
                       "x": "16:00",
                       "y": 7
                     },
                     {
                       "x": "17:00",
                       "y": 4.6
                     },
                     {
                       "x": "18:00",
                       "y": 5
                     },
                     {
                       "x": "19:00",
                       "y": 5
                     }
                   ]
                 },
                 {
                   "id": "Connector 3",
                   "color": "hsl(47, 70%, 50%)",
                   "data": [
                     {
                       "x": "08:00",
                       "y": 5
                     },
                     {
                       "x": "09:00",
                       "y": 7.4
                     },
                     {
                       "x": "10:00",
                       "y": 7.4
                     },
                     {
                       "x": "11:00",
                       "y": 7.4
                     },
                     {
                       "x": "12:00",
                       "y": 7.4
                     },
                     {
                       "x": "13:00",
                       "y": 15
                     },
                     {
                       "x": "14:00",
                       "y": 15
                     },
                     {
                       "x": "15:00",
                       "y": 22
                     },
                     {
                       "x": "16:00",
                       "y": 22
                     },
                     {
                       "x": "17:00",
                       "y": 22
                     },
                     {
                       "x": "18:00",
                       "y": 14
                     },
                     {
                       "x": "19:00",
                       "y": 7.4
                     }
                   ]
                 },
                 {
                   "id": "Connector 4",
                   "color": "hsl(251, 70%, 50%)",
                   "data": [
                      {
                        "x": "08:00",
                        "y": 7.4
                      },
                      {
                        "x": "09:00",
                        "y": 5.3
                      },
                      {
                        "x": "10:00",
                        "y": 5
                      },
                      {
                        "x": "11:00",
                        "y": 5.5
                      },
                      {
                        "x": "12:00",
                        "y": 7
                      },
                      {
                        "x": "13:00",
                        "y": 5
                      },
                      {
                        "x": "14:00",
                        "y": 7
                      },
                      {
                        "x": "15:00",
                        "y": 7
                      },
                      {
                        "x": "16:00",
                        "y": 7.4
                      },
                      {
                        "x": "17:00",
                        "y": 7.4
                      },
                      {
                        "x": "18:00",
                        "y": 4
                      },
                      {
                        "x": "19:00",
                        "y": 2
                      }
                   ]
                 }];


const { Meta } = Card;

  const { Title, Text } = Typography;

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  const [reverse, setReverse] = useState(false);

const MyResponsiveLine = ({ data /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        xFormat=" >-e"
        yScale={{
            type: 'linear',
            min: 0,
            max: 25,
            stacked: false,
            reverse: false
        }}
        curve="cardinal"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Times',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Power Consumption (KW)',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={{ scheme: 'red_blue' }}
        lineWidth={5}
        enablePoints={false}
        pointSize={10}
        pointColor="#ffffff"
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaBlendMode="overlay"
        areaOpacity={0.45}
        useMesh={true}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 16,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

  const count = [
    {
           today: "Station Usage",
           title: "2 Stations",
           icon: <img alt="Station" src={evStationA} width="55px" height="60px" />,
           bnb: "bnb2",
    },
    {
           today: "Station Available",
           title: "1 Station",
           icon: <img alt="Station" src={evStationB} width="55px" height="60px" />,
           bnb: "bnb2",
    },
    {
           today: "Station Unavailable",
           title: "1 Station",
           icon: <img alt="Station" src={evStationC} width="55px" height="60px" />,
           bnb: "bnb2",
    },
    {
           today: "Station Error",
           title: "0 Station",
           icon: <img alt="Station" src={evStationD} width="55px" height="60px" />,
           bnb: "bnb2",
    },
  ];
const { Column } = Table;

    return (
        <div>
            <Typography.Title></Typography.Title>
        <Row gutter={[24, 24]}>
          {ChargerDashboardsCountStatusColumns.map((c, dataIndex) => (
            <Col
              key={dataIndex}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                         {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
          {CountChargerDashboardsCountStatusColumns.map((c, dataIndex) => (
            <Col
              key={dataIndex}
              xs={24}
              sm={24}
              md={12}
              lg={4}
              xl={4}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                         {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}

            <Col
              xs={24}>
              <Card bordered={false} className="criclebox ">
                <div>
                  <Row align="middle" gutter={[24, 0]} style={{ height: '450px', display: 'flex', width:'100%' }}>
                  <Typography.Title level={3}>Power Consumption</Typography.Title>
                  <div style={{ height: '90%', display: 'flex', width:'100%' }}>
                        <MyResponsiveLine data={lineData}/>
                  </div>
                  </Row>
                </div>
              </Card>
            </Col>

            <Col
              xs={18}>
              <Card bordered={false} className="criclebox ">
                <div style={{ height: '100%', display: 'flex', width:'100%' }}>
                  <Row align="middle" gutter={[18, 0]} style={{ height: '100%', display: 'flex', width:'100%' }}>
                  <Typography.Title level={3}>List of Connector</Typography.Title>
                  <div style={{ height: '758px', width:'100%' }}>
                        <Table columns={ConnectorColumns} dataSource={ConnectorDataList} />
                  </div>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              xs={6}>
              <Card bordered={false} className="criclebox ">
                <div>
                  <Row align="middle" gutter={[24, 0]} style={{ height: '100%', display: 'flex', width:'100%' }}>
                  <Typography.Title level={3}>Income History</Typography.Title>
                  <div style={{ height: '100%', display: 'flex', width:'100%' }}>
                        <div className="timeline-box">
                                        <Title level={5}>Orders History</Title>
                                        <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                                          this month
                                        </Paragraph>

                                        <Timeline
                                          pending="Recording..."
                                          className="timelinelist"
                                          reverse={reverse}
                                        >
                                          {billingDataList.map((billingDataList, twoDigitChargePrice) => (
                                            <Timeline.Item color={billingDataList.color} key={twoDigitChargePrice}>
                                              <Title level={5}>{billingDataList.twoDigitChargePrice} THB</Title>
                                              <Text>{billingDataList.calculateMeterValue} kW</Text>
                                            </Timeline.Item>
                                          ))}
                                        </Timeline>
                                        <Button
                                          type="primary"
                                          className="width-100"
                                          onClick={() => setReverse(!reverse)}
                                        >
                                          {<MenuUnfoldOutlined />} REVERSE
                                        </Button>
                                      </div>
                  </div>
                  </Row>
                </div>
              </Card>
            </Col>
        </Row>
        </div>
    );
};

export default Dashboard;