import React, { useEffect, useState, Fragment} from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import hotelsLogoTest from './../../assets/images/hotels-logo-test.png';
import THSarabun from "./../../assets/fonts/THSarabun.ttf";
import THSarabunBoldItalic from "./../../assets/fonts/THSarabunBoldItalic.ttf";
import THSarabunBold from "./../../assets/fonts/THSarabunBold.ttf";
import THSarabunItalic from "./../../assets/fonts/THSarabunItalic.ttf";

// Create styles
Font.register(
    {
      family: 'THSarabun',
      src: THSarabun
    }
);

const styles = StyleSheet.create({
    body: {
      //paddingTop: 35,
      //paddingBottom: 65,
      //paddingHorizontal: 35,
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    title: {
      fontSize: 24,
      textAlign: 'left',
      fontFamily: 'THSarabun'
    },
    author: {
      fontSize: 12,
      textAlign: 'left',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      //margin: 12,
      margin: 5,
      fontFamily: 'THSarabun'
    },
    text: {
      //margin: 12,
      margin: 5,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'THSarabun'
    },
    detailtext: {
      //margin: 12,
      margin: 5,
      fontSize: 11,
      //textAlign: 'center',
      fontFamily: 'THSarabun'
    },
    image: {
      //marginVertical: 15,
      //marginHorizontal: 100,
      //backgroundColor:'blue',
      width:'20%',
      display:'flex',
      align:'center'

    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'left',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    pageFooterDetail: {
      position: 'absolute',
      fontSize: 12,
      bottom: 60,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    theader : {marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},
    theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},
    tbody:{
        fontSize : 12,
        paddingTop: 4 ,
        paddingLeft: 7 ,
        flex:1,
        borderColor : 'whitesmoke',
        borderRightWidth:1,
        borderBottomWidth:1,
        fontFamily: 'THSarabun',
    },
    total:{
        fontSize : 12,
        paddingTop: 4 ,
        paddingLeft: 7 ,
        flex:1.5,
        borderColor : 'whitesmoke',
        borderBottomWidth:1,
        fontFamily: 'THSarabun',
    },
    tbody2:{
        flex:2,
        borderRightWidth:1,
        fontFamily: 'THSarabun',
    }

});


const ReportPdfFile = (props) => {
    useEffect(() => {
        console.log("ReportPdfFile : Enter useEffect >> idTag="+props.item.idTag+", invoiceNo="+props.invoiceNo);

    }, []);

    return (
        <Document>
            <Page style={styles.body}>
               <View style={{ width:'90%', flexDirection :'row', marginTop:30}}>
                    <View span={3} style={{ width:'40%', paddingLeft:30}}>
                        <Image style={styles.image} src={hotelsLogoTest} style={{display:'flex', width:"60%", align:'center'}} />
                    </View>
                    <View style={{ width:'80%', textAlign:'left'}}>
                        <Text style={styles.detailtext} style={{ fontSize:14, textAlign:'left', paddingTop:20, fontFamily: 'THSarabun'}}>บริษัท ทดสอบระบบ จำกัด (มหาชน)</Text>
                        <Text style={styles.detailtext} style={{ fontSize:14, textAlign:'left', fontFamily: 'THSarabun'}}>
                        9876/56 อาคารทดสอบ ชั้น 14 ถ.สุขุมวิท แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพ 10110
                        </Text>
                    </View>
               </View>
                <View style={{ width:'90%', flexDirection :'row', marginTop:10, paddingLeft:30, fontFamily: 'THSarabun'}}>
                    <Text style={{  textAlign:'right', fontSize:10, paddingLeft:'82%'}} >
                          วันที่ที่ทำรายการ : 01/01/2567
                    </Text>
                </View>

               <View style={{ width:'90%', flexDirection :'row', paddingTop:30}}>

                    <View style={{ width:'90%', textAlign:'left', paddingLeft:30}}>
                        <Text style={styles.detailtext}>
                             สาขา : เทอมินอล 22 (รหัสสาขา 100007)
                        </Text>
                        <Text style={styles.detailtext}>
                             เลขประจำตัวผู้เสียภาษี : 1234567890123
                        </Text>
                        <Text style={styles.detailtext}>
                             ใบเสร็จรับเงิน / ใบกำกับภาษีอย่างย่อ
                        </Text>
                        <Text style={styles.detailtext}>
                             เอกสารเลขที่ {props.invoiceNo}
                        </Text>
                    </View>
                    <View span={3} style={{ width:'90%', textAlign:'left', paddingLeft:30}}>
                    </View>

               </View>

                <View style={{ width:'90%', flexDirection :'row', marginTop:10, paddingLeft:30}}>
                    <View style={[styles.theader, styles.theader2]}>
                        <Text >Items Detail</Text>
                    </View>
                    <View style={styles.theader}>
                        <Text>Duration</Text>
                    </View>
                    <View style={styles.theader}>
                        <Text>Energy (kWh)</Text>
                    </View>
                    <View style={styles.theader}>
                        <Text>Charge Price (THB)</Text>
                    </View>
                </View>

                <Fragment key='1'>
                    <View style={{ width:'90%', flexDirection :'row', paddingLeft:30}}>
                        <View style={[styles.tbody, styles.tbody2]}>
                            <Text>1. ค่าบริการชาร์จประจุ</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{props.item.chargeTimeDisplay}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{props.item.calculateMeterValue}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{props.item.twoDigitChargePrice}</Text>
                        </View>
                    </View>
                </Fragment>

                <View style={{ width:'90%', flexDirection :'row', paddingLeft:30}}>
                    <View style={styles.total}>
                        <Text></Text>
                    </View>
                    <View style={styles.total}>
                        <Text> </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>Total</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>
                            {props.item.twoDigitChargePrice}
                        </Text>
                    </View>
                </View>

              <Text style={styles.pageFooterDetail}  fixed>
                ************ VAT Included ************
              </Text>

              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
            </Page>
        </Document>
    );
};

export default ReportPdfFile;