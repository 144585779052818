import React, { useEffect, useState} from 'react';
import {Typography, Space, Table, Tag, Row, Col, Button, Input, Divider, Badge} from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import styled from "styled-components";
import {LogoutOutlined,} from '@ant-design/icons';
import StationImage from 'assets/images/Station.JPG'
import {Link} from "react-router-dom";
import axios from "axios";

const { Search } = Input;
const { Meta } = Card;

const OCPP16_2 = () => {

    const [messageApi, contextHolder] = message.useMessage();

    const [chargeBoxDataList, setChargeBoxDataList] = useState([]);

    useEffect(() => {
        console.log("OCPP16_2 : Enter useEffect : init search");
        onSearchAll();

        const interval = setInterval(onSearchAll, 5000); //Implementing the setInterval method >> 5 Sec.
        return () => clearInterval(interval); //Clearing the interval
    }, []);

    const onSearchAll = async () => {
        console.log("OCPP16_2 : Enter onSearchAll");
        try {
            const response = await axios.get("/api/v1/chargebox/chargeboxsforocpp");
            console.log("OCPP16_2 : after chargeboxsforocpp");
            console.log(response.data);
            setChargeBoxDataList( response.data );

            /*
            axios.get("/api/v1/chargebox/chargeboxsforocpp").then((response) => {
               console.log("OCPP16_2 : after chargeboxsforocpp");
               console.log(response.data);
               setChargeBoxDataList( response.data );
            });
            */
        } catch (e) {
             console.log(e);
        }
    };

    const remoteStart = async (chargeBoxId, connectorId) => {
        console.log("OCPP16_2 : Enter remoteStart(chargeBoxId="+chargeBoxId+", connectorId="+connectorId+")");
        try {
            //const email = "user@gmail.com"; //TODO
            const email = ""; //ไม่ต้องส่งค่าไป เด่วไปดึง email ของ user ที่ login web อยู่

            axios.post("/api/v1/ocpp-remote/remote-start-transaction", {
                  chargeBoxId: chargeBoxId,
                  connectorId: ""+connectorId,
                  idTag: email,
                  email: email
                })
                .then((response) => {
                  console.log(response);
                  if(response.data!=null && response.data.message === "remoteStartTransaction is success"){
                    messageApi.open({
                          type: "success",
                          content: "Remote-Start : Success",
                        });

                    /*
                    setTimeout( () =>{
                        console.log("waite for 9 sec");
                        onSearchAll();
                    }, 9000);
                    */

                  }else{
                    messageApi.open({
                          type: "error",
                          content: "Remote-Start : "+ response.data.message,
                        });
                  }
                });
        } catch (e) {
             console.log(e);
             messageApi.open({
                   type: "error",
                   content: "Remote-Start : failed ",
                 });
        }
    };

    const remoteStop = async (chargeBoxId, connectorId) => {
        console.log("OCPP16_2 : Enter remoteStop(chargeBoxId="+chargeBoxId+", connectorId="+connectorId+")");
        const params = { chargeboxid: chargeBoxId, connectorid:""+connectorId };
        try {
            axios.post("/api/v1/ocpp-remote/remote-stop-transaction", {
                    chargeBoxId: chargeBoxId,
                    connectorId: ""+connectorId
                })
                .then((response) => {
                    console.log(response);
                    if(response.data!=null && response.data.message === "remoteStopTransaction is success"){
                      messageApi.open({
                            type: "success",
                            content: "Remote-Stop : Success",
                          });

                      /*
                      setTimeout( () =>{
                          console.log("waite for 9 sec");
                          onSearchAll();
                      }, 9000);
                      */

                    }else{
                        messageApi.open({
                              type: "error",
                              content: "Remote-Stop : "+ response.data.message,
                            });
                    }
                });
        } catch (e) {
             console.log(e);
             messageApi.open({
                   type: "error",
                   content: "Remote-Stop : failed",
                 });
        }
    };

    const unlockConnector = async (chargeBoxId, connectorId) => {
        console.log("OCPP16_2 : Enter unlockConnector(chargeBoxId="+chargeBoxId+", connectorId="+connectorId+")");
        try {
            axios.post("/api/v1/ocpp-remote/unlock-connector", {
                    chargeBoxId: chargeBoxId,
                    connectorId: ""+connectorId
                })
                .then((response) => {
                    console.log(response);
                    if(response.data!=null && response.data.message === "unlockConnector is success"){
                      messageApi.open({
                            type: "success",
                            content: "Unlock Connector : Success",
                          });

                      /*
                      setTimeout( () =>{
                         console.log("waite for 9 sec");
                         onSearchAll();
                      }, 9000);
                      */

                    }else{
                        messageApi.open({
                              type: "error",
                              content: "Unlock Connector : "+ response.data.message,
                            });
                    }
                });
        } catch (e) {
             console.log(e);
             messageApi.open({
                   type: "error",
                   content: "Unlock Connector : failed ",
                 });
        }
    };

    const renderComponent = () =>{
        console.log("OCPP16_2 : Enter renderComponent");

        const list =  chargeBoxDataList.map(chargeBoxItem =>
                        <div>
                            <Divider orientation="left">Charger ID: {chargeBoxItem.chargeBoxId} ({chargeBoxItem.displayStatus}) </Divider>
                            <Row>
                               {

                                    chargeBoxItem.connectors.length > 0
                                    && chargeBoxItem.connectors.map(connectorItem => (
                                        <Col span={6} xs={{ order: 4 }} sm={{ order: 3 }} md={{ order: 2 }} lg={{ order: 1 }}>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                                <Card
                                                    hoverable
                                                    style={{ width: 300 }}
                                                    cover={
                                                       <img
                                                            alt="Station"
                                                            src={StationImage} style={{ width: 180, alignSelf: 'center', marginLeft: 45, marginTop: 10}}
                                                        />
                                                    }
                                                    actions={[
                                                            <Tooltip title="Remote-Start" color="purple" key="purple">
                                                                <PlayCircleOutlined key="remoteStart" onClick={() => remoteStart(chargeBoxItem.chargeBoxId, connectorItem.connectorId)} />
                                                            </Tooltip>,
                                                            <Tooltip title="Remote-Stop" color="purple" key="purple">
                                                                <StopOutlined key="remoteStop" onClick={() => remoteStop(chargeBoxItem.chargeBoxId, connectorItem.connectorId)} />
                                                            </Tooltip>,
                                                            <Tooltip title="Unlock-Connector" color="purple" key="purple">
                                                                <SettingOutlined key="unlockConnector" onClick={() => unlockConnector(chargeBoxItem.chargeBoxId, connectorItem.connectorId)} />
                                                            </Tooltip>,
                                                            /*<Tooltip title="Edit" color="purple" key="purple">
                                                                <EditOutlined key="edit" />
                                                            </Tooltip>,
                                                            <EllipsisOutlined key="ellipsis" />,*/
                                                        ]}
                                                    >
                                                    <Meta
                                                        //avatar={connectorItem.status=="Available"? <Badge style={{width:65}} count={"Available"} showZero color="#faad14" />:<Badge style={{width:65}} count={"Offline"} showZero color="#ff0000" />}
                                                        avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}

                                                        //title={"Connector ID: "+ connectorItem.connectorId + " [" + connectorItem.status!="Available"?"YEs":"No" + " ]"}

                                                        title={connectorItem.status=="Available"?
                                                                    <div>Connector :  {connectorItem.connectorId} <Badge style={{width:65}} count={"Available"} showZero color="green" /></div>:
                                                                    connectorItem.status=="Preparing"?
                                                                    <div>Connector :  {connectorItem.connectorId} <Badge style={{width:65}} count={"Preparing"} showZero color="cyan" /></div>:
                                                                    connectorItem.status=="Charging"?
                                                                    <div>Connector :  {connectorItem.connectorId} <Badge style={{width:65}} count={"Charging"} showZero color="yellow" /></div>:
                                                                    connectorItem.status=="Finishing"?
                                                                   <div>Connector :  {connectorItem.connectorId} <Badge style={{width:65}} count={"Finishing"} showZero color="purple" /></div>:
                                                                   <div>Connector :  {connectorItem.connectorId} <Badge style={{width:65}} count={"Offline"} showZero color="red" /></div> }
                                                        description={connectorItem.meterValueMap==null?
                                                                    <div>Connector :  Power: 0 W </div>:
                                                                    <div>Power Offer: {connectorItem.meterValueMap.PowerOffered} </div> }

                                                    />
                                                </Card>
                                            </div>
                                        </Col>
                                    ))
                               }
                            </Row>
                            <hr/>
                        </div>
        );

        return ( <div>{list}</div> );
    };

    return (
        <div>
                <Typography.Title>OCPP Version 1.6</Typography.Title>
                <br/>
                <Search placeholder="input search text" enterButton="Search" size="large" loading />
                <br/>
                <div>
                    { renderComponent() }
                </div>
                {contextHolder}
        </div>
    );

};

export default OCPP16_2;