import React, { useEffect, useState} from 'react';
import {Typography, Space, Table, Tag, Col, Button, Row, Input, Tooltip } from "antd";
import {Link} from "react-router-dom";
import {PlusOutlined, AudioOutlined, FilePdfOutlined, DownloadOutlined} from "@ant-design/icons";
import type { SearchProps } from '../Search';
import axios from "axios";

import { PDFDownloadLink, ReactPDF, pdf, BlobProvider } from '@react-pdf/renderer';
import ReportPdfFile from "./ReportPdfFile";
import ReportPdfFileTh from "./ReportPdfFileTh";
import { saveAs } from "file-saver";

const { Column } = Table;
const { Search } = Input;
const onSearch = (value, _e, info) => console.log(info?.source, value);

const suffix = (

  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);

const generateBillingPdf = async (record, actionType) => {
    try {
        if(record!=null){
            console.log("Enter generateBillingPdf");
            axios.post("/api/v1/billing/genInvoiceNo/"+record.reportName+"/"+record.reportFullName+"/"+record.idTag).then((response) => {
                console.log(response.data);
                if(response.data.errorMsg==""){
                    if(actionType==="print"){
                        printPdfAndDisplay(response.data.invoiceNo, record);
                    }else if(actionType==="download"){
                        printPdfAndSave(response.data.invoiceNo, record);
                    }
                }else{
                    alert(response.data.errorMsg);
                }
            });
        }
    } catch (e) {
         console.log(e);
    }
};

const printPdfAndSave = async (invoiceNo, record) => {
    console.log("Enter printPdfAndSave");
    try {
        if(record!=null){
            const blob = await pdf((
                            <ReportPdfFileTh
                                item={record}
                                invoiceNo={invoiceNo}
                            />
                         )).toBlob();
            saveAs(blob, record.reportFullName);
        }
    }catch (e) {
        console.log(e);
    }
};

const printPdfAndDisplay = async (invoiceNo, record) => {
    console.log("Enter printPdfAndDisplay");
    try {
        if(record!=null){
            //Create a Blob from the PDF Stream
            const blob = await pdf((
                            <ReportPdfFileTh
                                item={record}
                                invoiceNo={invoiceNo}
                            />
                         )).toBlob();

            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);

            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        }
    }catch (e) {
        console.log(e);
    }
};

const billingColumns = [
  {
    title: 'User',
    dataIndex: 'userFullName',
    key: 'userFullName',
  },
  {
    title: 'Type',
    dataIndex: 'startChargeByDisplay',
    key: 'startChargeByDisplay',
  },
  {
    title: 'Charger Name',
    dataIndex: 'chargeBoxId',
    key: 'chargeBoxId',
  },
  {
    title: 'Connector ID',
    dataIndex: 'connectorId',
    key: 'connectorId',
  },
  {
    title: 'Start Time',
    dataIndex: 'startTimestampDisplay',
    key: 'startTimestampDisplay',
  },
  {
    title: 'Stop Time',
    dataIndex: 'stopTimestampDisplay',
    key: 'stopTimestampDisplay',
  },
  {
    title: 'Charge Time',
    dataIndex: 'chargeTimeDisplay',
    key: 'chargeTimeDisplay',
  },
  {
    title: 'Energy (kWh)',
    dataIndex: 'calculateMeterValue',
    key: 'calculateMeterValue',
  },
  {
    title: 'Charge Price (THB)',
    dataIndex: 'twoDigitChargePrice',
    key: 'twoDigitChargePrice',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Tooltip title="Print">
            <FilePdfOutlined style={{ fontSize: '18px' }} onClick={() => generateBillingPdf(record, 'print')} />
        </Tooltip>
        <Tooltip title="Download">
            <DownloadOutlined style={{ fontSize: '18px' }} onClick={() => generateBillingPdf(record, 'download')} />
        </Tooltip>
      </Space>
    ),
  },
];

const BillingSummary = () => {
    const [billingDataList, setBillingDataList] = useState([]);
    useEffect(() => {
        console.log("BillingList : Enter useEffect : init search");
        onSearchAll();
    }, []);

    const onSearchAll = async () => {
        console.log("BillingList : Enter onSearchAll");
        try {
            axios.get("/api/v1/billing/billings").then((response) => {
               console.log(response.data);
               setBillingDataList( response.data );
            });
        } catch (e) {
             console.log(e);
        }
    };

    return (

        <div>
                <Row justify="start" align="middle" gutter={8}>

                    <Col style={{ width:'25%' }}>
                        <Typography.Title level={4} style={{ margin: 16 }}>Billing List</Typography.Title>
                    </Col>

                    <Col style={{ width:'50%' }}>
                        <form><Search placeholder="input search text" enterButton="Search" suffix={suffix} onSearch={onSearch}/></form>
                    </Col>

                </Row>

            <Table columns={billingColumns} dataSource={billingDataList} />

        </div>


    );


};

export default BillingSummary;