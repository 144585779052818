import {createContext, useEffect, useState} from "react";
import axios from "axios";
import moment from "moment-timezone";
import {notification} from "antd";
import appRouter from 'routers/app.router'
import authRouter from 'routers/auth.router'

export const AuthContext = createContext();


const AuthProvider = ({children}) => {

    const [user, setUser] = useState();
    const [isReady, setReady] = useState();

    useEffect(() => {
        const token = localStorage.getItem("_t");
        if (token) {
            const tempUser = extractUser(token);
            if (tempUser) {
                setUser(tempUser)
            }
        }
        setReady(true)
    }, []);
    const login = async (email, password) => {
        try {
            const {data, status} = await axios.post('/api/v1/auth/authenticate', {email, password});
            if (status === 200) {
                const {token} = data;
                const tempUser = extractUser(token);
                if (tempUser) {
                    setUser(tempUser)
                }
                localStorage.setItem("_t", token);
            }
        } catch (e) {
            notification.error({
                message: "Login Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        }
    }
    const onGoogleSignIn = async res => {
        try {
            const {credential} = res;
            const {data, status} = await axios.post('/api/v1/auth/credential/google', {idToken: credential});
            if (status === 200) {
                const {token} = data;
                const tempUser = extractUser(token);
                if (tempUser) {
                    setUser(tempUser)
                }
                localStorage.setItem("_t", token);
            }
        } catch (e) {
            notification.error({
                message: "Login Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        }
    }
    const logout = async () => {
        setUser(null);
        await axios.post('/api/v1/auth/signout')
        localStorage.removeItem("_t");
    }

    return (
        <AuthContext.Provider
            value={{login, logout, onGoogleSignIn, isReady, user, router: user ? appRouter : authRouter}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export function useAuthContext() {


}

export function extractUser(token) {
    if (!token) return null;
    const {email, firstName, lastName, middleName, exp} = parseJwt(token);
    if(!email || !exp) return null;
    const expired = moment(exp * 1000);
    const u = {email, firstName, lastName, middleName, expired, token};
    return expired.isBefore() ? null : u;
}

export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    if (!base64Url) return {};
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}