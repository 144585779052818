import React, {useContext, useRef} from 'react';
import styled from "styled-components";
import {Button, Divider, Form, Input, Typography} from "antd";
import {AuthContext} from "AuthContext";
import {useNavigate} from "react-router-dom";
import useScript from "../hook/useScript";


const Login = () => {
    const googleSignInButtonRef = useRef();
    const {onGoogleSignIn} = useContext(AuthContext);
    const navigator = useNavigate();
    const {login} = useContext(AuthContext)
    const onFinish = (values) => {
        const {email, password} = values;
        login(email, password);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useScript("https://accounts.google.com/gsi/client", () => {
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: onGoogleSignIn,
        });

        window.google.accounts.id.renderButton(
            googleSignInButtonRef.current,
            {theme: 'filled_blue', size: 'large', width: 300}
        );
    })
    return (
        <FormStyled
            name="basic"
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            style={{width: 'minmax(400px, 90%)'}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Typography.Title style={{textAlign: 'center', marginTop: 0, marginBottom: 40}}>Log in</Typography.Title>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!'
                    }
                ]}
            >
                <Input placeholder="Enter Email"/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder="Enter Password"/>
            </Form.Item>
            <FormAction
                wrapperCol={{
                    offset: 6,
                    span: 18,
                }}
            >
                <Button type="primary" htmlType="submit" style={{width: 120}}>
                    Sign in
                </Button>
                <Button type="link" onClick={() => navigator("forget_password")}>
                    Forget Password ?
                </Button>
            </FormAction>
            <Divider>OR</Divider>
            <GoogleButton ref={googleSignInButtonRef}></GoogleButton>
            <Divider/>
            <div style={{textAlign: "center"}}>
                <Button style={{textAlign: "right"}} type="link" onClick={() => navigator("signup")}>
                    New user? Sign up
                </Button>
            </div>
        </FormStyled>
    );
};

export default Login;

const FormStyled = styled(Form)`
  min-width: 500px;
  width: 90%;
  max-width: 600px;
  background: #00000088;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
`;
const GoogleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
