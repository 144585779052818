import React, {useEffect, useState} from 'react';
import {App, Typography, Space, Table, Tag, Button, Row, Col, Input} from "antd";
import {PlusOutlined, AudioOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import axios from "axios";


const { Column } = Table;
const { Search } = Input;
const onSearch = (value, _e, info) => console.log(info?.source, value);

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);

const UserList = () => {

const usersColumns = [
  {
    title: 'Username',
    dataIndex: 'userName',
    key: 'userName',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Firstname',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Middle name',
    dataIndex: 'middleName',
    key: 'middleName',
  },
  {
    title: 'Lastname',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Link to={''+record.userName} ><Button type="link"><EditOutlined/></Button></Link>
        <a onClick={() => onDelete(record)}><DeleteOutlined/></a>
      </Space>
    ),
  },
]

    const [usersDataList, setUsersDataList] = useState([]);

    useEffect(() => {
        console.log("UserList : Enter useEffect : init search");
        onSearchAllUsers();
    }, []);

    const onSearchAllUsers = async () => {
        console.log("UserList : Enter onSearchAllUsers");
        try {
            axios.get("/api/v1/user/users").then((response) => {
               console.log(response.data);
               setUsersDataList( response.data );
            });
        } catch (e) {
             console.log(e);
        }
    };

    const handleAddUser = () => {

    }
    const {notification, modal} = App.useApp();
    const onDelete = async (values) => {
            console.log("=== User Delete : onDelete ===");
            //console.log(values);

            const deleteData = {
                userName: values.userName,
             };
            console.log(deleteData);

            try {

                //await axios.post("/api/v1/rfid/register", values);
                await axios.delete("/api/v1/user/deleteByUsername/"+values.userName);
                //await axios.post("/api/v1/rfid/deleteByIdTag/"+values.idTag);

                modal.success({
                    title: ' User delete successfully',
                    content: values.userName+' delete successfully',
                    //onOk: gotoLogin
                })
                onSearchAllUsers();
            } catch (e) {
                notification.error({
                    message: "Delete User Error",
                    description: e.response?.data?.message || "Something went wrong, please try again later"
                })
            } finally {
                console.log("=== User delete : onDelete : finally ===");
            }
    };

    return (
        <div>
            <Row justify="start" align="middle" gutter={8}>
                <Col style={{ width:'25%' }}>
                    <Typography.Title level={4} style={{ margin: 16 }}>User List </Typography.Title>
                </Col>
                <Col style={{ width:'50%' }}>
                    <form><Search placeholder="input search text" enterButton="Search" suffix={suffix} onSearch={onSearch}/></form>
                </Col>
                <Col style={{ width:'25%' }} align="right">
                    <Link to="add"><Button type="primary" icon={<PlusOutlined />} >New User</Button> </Link>
                </Col>
            </Row>
  <Table columns={usersColumns} dataSource={usersDataList} />
        </div>
    );
};

export default UserList;