import {Typography} from "antd";
import styled from "styled-components";
import React, { useState, useRef, useEffect } from 'react'
import { Map, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Avatar, Card } from 'antd';
import { Col, Divider, Row, Timeline, Button } from 'antd';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css'
import "./map.css"
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import axios from "axios";

const chargeBoxColumns = [
  {
    title: 'ChargeBoxId',
    dataIndex: 'chargeBoxId',
    key: 'chargeBoxId',
  },
  {
    title: 'Location',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Status',
    dataIndex: 'registrationStatus',
    key: 'registrationStatus',
  },
];


const Location = () => {

  const [chargeBoxDataList, setChargeBoxDataList] = useState([]);

  const position = [13.756331, 100.501762];

  const ZOOM_LEVEL = 13

  /*
  const markers = [
    {
        geocode: [13.756331, 100.501762],
        popUp: 'chargeBoxId'
    },
    {
        geocode: [13.769431722484253, 100.60658460129652],
        popUp: 'Cafe De test'
    },
    {
        geocode: [13.758885934501672, 100.63662534043665],
        popUp: 'รพ. รามคำแหง'
    }
  ]
  */

  const customIcon = new Icon({
    iconUrl: require("assets/images/placeholder.png"),
    iconSize: [38, 38]
  })

  useEffect(() => {
      console.log("Location : Enter useEffect : init search");
      onSearchAll();
  }, []);

  const onSearchAll = async () => {
      console.log("Location : Enter onSearchAll");
      try {
          axios.get("/api/v1/location/chargers").then((response) => {
             console.log(response.data);
             setChargeBoxDataList( response.data );
          });
      } catch (e) {
           console.log(e);
      }
  };

    return (
            <Card bordered={false} className="criclebox ">
                              <Row align="middle" gutter={[24, 0]} style={{ height: '100%', display: 'flex', width:'100%' }}>
                              <div style={{ height: '758px', width:'100%' }}>
                                <MapContainer center={position} zoom={ZOOM_LEVEL}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    {
                                        chargeBoxDataList.map(marker => (
                                                <Marker position={marker.geocode} icon={customIcon}>
                                                    <Popup>{marker.popUp}</Popup>
                                                </Marker>
                                        ))
                                    }
                </MapContainer>
                 </div>
              </Row>
            </Card>
    );
};

export default Location;
