import {createBrowserRouter} from 'react-router-dom';
import AppLayout from 'components/AppLayout'
import {
    AimOutlined,
    BarsOutlined,
    ContainerOutlined,
    DashboardOutlined,
    FileOutlined,
    MailOutlined,
    MessageOutlined,
    MinusOutlined,
    NodeExpandOutlined,
    NotificationOutlined,
    PlusOutlined,
    SettingOutlined,
    UserAddOutlined,
    UserOutlined,
    FileTextOutlined,
    FileDoneOutlined,
    MoneyCollectOutlined,
    ExportOutlined,
    WifiOutlined
} from "@ant-design/icons";
import Dashboard from "components/dashboard";
import Location from "components/location";
import OCPP16 from "components/ocpp/OCPP16"
import OCPP201 from "components/ocpp/OCPP201"
import Logs from "components/logs";
import ChargerList from "components/station/ChargerList";
import ChargerAdd from "components/station/ChargerAdd";
import ChargerMaintenance from "components/station/ChargerMaintenance";
import ChargerDiagnostic from "components/station/ChargerDiagnostic";
import UserList from "components/user/UserList";
import UserAdd from "components/user/UserAdd";
import RFIDList from "components/rfid/RFIDList";
import RFIDAdd from "components/rfid/RFIDAdd";
import NotificationLine from "components/notification/NotificationLine";
import NotificationEmail from "components/notification/NotificationEmail";
import BillingSummary from "components/billing/BillingSummary";
import CreateInvoice from "components/billing/CreateInvoice";
import ExportFile from "components/billing/ExportFile";
import axios from "axios";
export default createBrowserRouter([
        {
            id: "root", path: "/", element: <AppLayout/>,
            children: [
                {path: "dashboard", label: 'Dashboard', icon: <DashboardOutlined/>, element: <Dashboard/>},
                {path: "location", label: 'Location', icon: <AimOutlined/>, element: <Location/>},
                {
                    path: "station", label: 'Station Management', icon: <ContainerOutlined/>,
                    children: [
                        {
                            index: true,
                            path: '',
                            label: 'List of Charge Point',
                            icon: <BarsOutlined/>,
                            element: <ChargerList/>
                        },
                        {path: "add", label: 'Add Charge Point', icon: <PlusOutlined/>, element: <ChargerAdd/>,show: false },
                        {path: ":chargeBoxId", label: 'Edit Charge Point', icon: <PlusOutlined/>, element: <ChargerAdd/>,show: false,
                            loader : async ({ params }) => {
                                const res = await axios.get(`/api/v1/chargebox/chargeboxs/${params.chargeBoxId}`);
                                const chargeBoxObj = res.data;
                                chargeBoxObj.registrationStatus = chargeBoxObj.registrationStatus === "Accepted";
                                return chargeBoxObj;
                            }
                        },
                        /*{
                            (path: "chargers/maintenance",
                            label: 'Maintenance Charge Point',
                            icon: <SettingOutlined/>,
                            element: <ChargerMaintenance/>
                        },
                        {
                            path: "chargers/diagnostic",
                            label: 'Diagnostic Charge Point',
                            icon: <NodeExpandOutlined/>,
                            element: <ChargerDiagnostic/>
                        }*/
                    ]
                },
                {
                    path: "ocpp", label: 'OCPP Management', icon: <ContainerOutlined/>,
                    children: [
                        {path: "v16", label: 'OCPP 1.6', icon: <MinusOutlined/>, element: <OCPP16/>},
                       // {path: "v201", label: 'OCPP201', icon: <MinusOutlined/>, element: <OCPP201/>}
                    ]
                },
                {
                    path: "user", label: 'User Management', icon: <UserOutlined/>, subMenuVisible:false,
                    children: [
                        {index: true, label: 'List User', icon: <BarsOutlined/>, element: <UserList/>},
                        {path: "add", label: 'Add User', icon: <UserAddOutlined/>, element: <UserAdd/>},
                        {path: ":username", label: 'Edit User', icon: <PlusOutlined/>, element: <UserAdd/>,show: false,
                            loader : async ({ params }) => {
                                console.log("router : Enter Edit USER");
                                const res = await axios.get(`/api/v1/user/users/${params.username}`);
                                const usernameObj = res.data;
                                console.log("router : Exit Edit USER");
                                return usernameObj;
                            }
                        }
                    ]
                },
                {
                    path: "rfid", label: 'RFID Management', icon: <WifiOutlined/>, subMenuVisible:false,
                    children: [
                        {
                            index: true,
                            path: '',
                            label: 'List RFID',
                            icon: <BarsOutlined/>,
                            element: <RFIDList/>
                        },
                        {path: "add", label: 'Add RFID', icon: <PlusOutlined/>, element: <RFIDAdd/>,show: false },
                        {path: ":ocppTagPk", label: 'Edit RFID', icon: <PlusOutlined/>, element: <RFIDAdd/>,show: false,
                            loader : async ({ params }) => {
                                console.log("router : Enter Edit RFID");
                                const res = await axios.get(`/api/v1/rfid/rfidtags/pk/${params.ocppTagPk}`);
                                const ocppTagObj = res.data;
                                console.log("router : Exit Edit RFID");
                                return ocppTagObj;
                            }
                        },
                    ]
                },
                {
                    path: "billing", label: 'Billing', icon: <MoneyCollectOutlined />,
                    children: [
                        //{path: "create", label: 'Create Invoice', icon: <FileTextOutlined/>, element: <CreateInvoice/>},
                        {path: "bill", label: 'Billing Summary', icon: <FileDoneOutlined/>, element: <BillingSummary/>},
                        //{path: "export", label: 'Export Report', icon: <ExportOutlined/>, element: <ExportFile/>}
                    ]
                },
                {
                    path: "notification", label: 'Notification', icon: <NotificationOutlined/>,
                    children: [
                        //{path: "line", label: 'Line Alert', icon: <MessageOutlined/>, element: <NotificationLine/>},
                        {path: "email", label: 'Email Alert', icon: <MailOutlined/>, element: <NotificationEmail/>}
                    ]
                },
               // {path: "log", label: 'Logs', icon: <FileOutlined/>, element: <Logs/>}
            ]
        },
    ]
);