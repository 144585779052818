import {useContext, useEffect, useReducer} from 'react';
import {LogoutOutlined,} from '@ant-design/icons';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Button, Layout, Menu, theme, Image } from 'antd';
import styled from "styled-components";
import appRouter from "routers/app.router"
import {AuthContext} from "../AuthContext";
import _ from 'lodash';
import logo from 'assets/images/uc-logo.jpg';

const {Header, Content, Footer, Sider} = Layout;

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return {...state, ...action.payload};
        case "collapsed":
            return {...state, collapsed: action.payload};
        case "updateOpenSubmenu":
            return {...state, openMenu: action.payload};
        case "selectMenu":
            return {...state, activeMenu: action.payload}
    }
}
const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {logout} = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, {collapsed: false, menuItem: [], openMenu: [], activeMenu: []});
    const {collapsed, menuItem, openMenu, activeMenu} = state;

    useEffect(() => {
        const {pathname:urlPath} = location;
        const openMenu = [];
        const pathname = urlPath.substring(1);
        const activeMenu = [];
        const routes = appRouter.routes[0]?.children;
        const menus = _.map(routes,
            ({label, path, icon, children,subMenuVisible=true}) => {
                const submenus = subMenuVisible&&_.map(children, ({label: lbl, path: subPath, icon,show=true}) => show&&{
                    label: lbl,
                    key: path + "/" + subPath,
                    icon
                }).filter(a=>!!a);
                if (submenus && pathname.includes(path)) {
                    openMenu.push(path)
                }
                if(pathname.startsWith(path)){
                    activeMenu.push(path)
                }
                activeMenu.push(pathname)
                return {label, key: path, icon, children: submenus.length ? submenus : undefined};
            })

        dispatch({type: "init", payload: {openMenu, activeMenu, menuItem: menus}})

        if (pathname === '/' && routes[0]) {
            handleMenuClick({key: routes[0].path});
        }
    }, []);

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const handleMenuClick = ({key}) => {
        if (key) {
            navigate(key);
            dispatch({type: "selectMenu", payload: key});
        }
    };

    const handleOpenSubmenu = openKeys => {
        dispatch({type: "updateOpenSubmenu", payload: openKeys})
    }

    const handleLogout = async () => {
        await logout();
        navigate("/")
        window.location.reload()
    }
    return (
        <Layout>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '90px'
                }}
            >
                <img src={logo} width={200} alt="Logo" height={70}/>
                <div style={{width: '100%'}}/>
                <Button type="primary" shape="round" icon={<LogoutOutlined/>} onClick={handleLogout}>Logout</Button>
            </Header>
            <Layout style={{height: 'calc(100dvh - 64px)'}}>
                <Sider width={300} collapsible collapsed={collapsed}
                       onCollapse={(value) => dispatch({type: "collapsed", payload: value})}>
                    <div className="demo-logo-vertical"/>
                    <Menu theme="dark" mode="inline" openKeys={openMenu} onOpenChange={handleOpenSubmenu}
                          selectedKeys={activeMenu} items={menuItem} onClick={handleMenuClick}/>
                </Sider>
                <Layout style={{height: 'calc(100dvh - 64px)', overflow: 'auto'}}>
                    <Content
                        style={{
                            margin: '0 16px',
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};
export default App;

const DemoLogo = styled.div`
  background-image:
  width: 250px;
  height: 32px;
  background: rgba(255, 255, 255, .2);
  border-radius: 6px;
`