import React, {useState} from 'react';
import styled from "styled-components";
import {App, Button, Col, Divider, Form, Input, Row, Select, Switch, Typography} from "antd";
import {useLoaderData, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {ArrowLeftOutlined} from "@ant-design/icons";

const ChargerAdd = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [isSubmiting, setSubmitting] = useState();
    const { chargeBoxId } = useParams();
    const {notification, modal} = App.useApp();

    const initForm = useLoaderData();

    const onFinish = async (values) => {
        console.log("=== onFinish ===");
        console.log(values);
        values.registrationStatus = values.registrationStatus ? "Accepted" : "Disconnected";
        try {
            setSubmitting(true);

            await axios.post("/api/v1/chargebox/add-new-profile", values);

            form.resetFields();
            modal.success({
                title: 'register charge box successfully',
                content: 'charge box profile is ready',
                //onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "register charge box error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
        }
    };

    const onUpdateProfile = async (values) => {
        console.log("Update Profile : Enter onUpdateProfile");
        console.log(values);
        try {
            await axios.put("/api/v1/chargebox/"+chargeBoxId, values);
            console.log("Update Profile : after onUpdateProfile");
            modal.success({
                title: 'update charge box successfully',
                content: 'charge box profile is update',
                //onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "Update charge box error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        }
    };
    return (
        <FormStyled
            name="basic"
            form={form}
            disabled={isSubmiting}
            initialValues={initForm}
            labelCol={{xs: 6, md: 8, sm: 6}}
            wrapperCol={{xs: 18, md: 16, sm: 18}}
            style={{width: 'minmax(400px, 90%)'}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Row justify="space-between" align="middle" gutter={[8, 40]}>
                <Col>
                    <Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button>
                </Col>
                <Col>
                    <Typography.Title level={4} style={{textAlign: 'center', margin: 0}}>Add Charge Point</Typography.Title>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row gutter={[48, 16]} justify="center">
                <Col lg={12}>
                    <Divider>Profile</Divider>
                    <Form.Item
                        label="ChargeBox ID"
                        name="chargeBoxId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your ChargeBox ID'
                            }
                        ]}
                    >
                        <Input disabled={!!chargeBoxId} placeholder="Enter ChargeBox ID"/>
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        name="registrationStatus"
                        valuePropName="checked"

                        rules={[
                            {
                                required: true,
                                message: 'Please check Registration Status',
                            },
                        ]}
                    >
                        <Switch checkedChildren="Accepted" unCheckedChildren="Disconnected" />
                    </Form.Item>

                    <Divider>Address</Divider>
                    <Form.Item
                        label="Street"
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your street'
                            }
                        ]}
                    >
                        <Input placeholder="Enter Street"/>
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="houseNumber"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your address'
                            }
                        ]}
                    >
                        <Input placeholder="Enter house number"/>
                    </Form.Item>

                    <Form.Item
                        label="zip code"
                        name="zipCode"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your zip code'
                            }
                        ]}
                    >
                        <Input placeholder="Enter zip code"/>
                    </Form.Item>

                    <Form.Item
                        label="city"
                        name="city"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your city'
                            }
                        ]}
                    >
                        <Input placeholder="Enter city"/>
                    </Form.Item>

                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your country',
                            },
                        ]}
                    >
                        <Select  placeholder="Select a country" optionFilterProp="children" options={[
                            {
                                value: 'TH',
                                label: 'Thailand',
                            },
                            {
                                value: 'MA',
                                label: 'Malaysia',
                            },
                            {
                                value: 'ID',
                                label: 'Indonesia',
                            },
                        ]}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12}>
                    <Divider>Misc.</Divider>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your description',
                            },
                        ]}
                    >
                        <Input placeholder="Enter description"/>
                    </Form.Item>

                    <Form.Item
                        label="Charger Address"
                        name="chargerAddress"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your charger address',
                            },
                        ]}
                    >
                        <Input placeholder="Enter charger address"/>
                    </Form.Item>

                    <Form.Item
                        label="Latitude"
                        name="locationLatitude"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your charger latitude',
                            },
                        ]}
                    >
                        <Input placeholder="Enter charger latitude"/>
                    </Form.Item>

                    <Form.Item
                        label="Longitude"
                        name="locationLongitude"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your charger longitude',
                            },
                        ]}
                    >
                        <Input placeholder="Enter charger longitude"/>
                    </Form.Item>

                    <Form.Item
                        label="Note"
                        name="additionalNote"
                        rules={[
                            {
                                required: false,
                                message: ' ',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <FormAction span={24}>
                    {chargeBoxId == null?
                    <Button type="primary" htmlType="submit" style={{width: 120}} loading={isSubmiting}>
                        Add
                    </Button>:
                    <Button type="primary" style={{width: 120}} onClick={() => onUpdateProfile(chargeBoxId)}>
                        Update
                    </Button>
                    }
                    <Button type="primary" style={{width: 120}} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </FormAction>
            </Row>
        </FormStyled>
    );
};

export default ChargerAdd;

const FormStyled = styled(Form)`
  margin: 32px auto 0;
    

`;
const FormAction = styled(Col)`
    display: flex;
    justify-content: center;
    gap: 16px;
`;