import React, {useState} from 'react';
import styled from "styled-components";
import {App, Button, Col, Divider, Form, Input, Row, Typography} from "antd";
import {useNavigate} from 'react-router-dom';
import axios from "axios";

const Register = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isSubmiting, setSubmitting] = useState();
    const [isOtpReady, setOtpReadyState] = useState();
    const {notification, modal} = App.useApp();
    const gotoLogin = () => {
        navigate("..", {replace: true, relative: "path"})
    }
    const onFinish = async (values) => {
        try {
            setSubmitting(true);
            await axios.post("/api/v1/auth/forget_password", values);
            form.resetFields();
            modal.success({
                title: 'Change password successfully',
                content: 'your new password is set back to Sign in',
                onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "Set New Password Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
        }


    };
    const requestOTP = async () => {
        let isEmailNotEmpty = false;
        try {
            await form.validateFields();
        } catch (e) {
            isEmailNotEmpty = !e.errorFields.find(f => f.name.includes("username"))
        }
        if (isEmailNotEmpty) {
            const email = form.getFieldValue("username");
            try {
                setSubmitting(true)
                const res = await axios.post("/api/v1/auth/reset-password-request", {email})
                modal.success({
                    title: 'OTP Send via Email',
                    content: res?.message ?? 'please check your email inbox (or junk). The otp valid 30 min',
                    onOk: () => {
                        form.resetFields()
                        form.setFieldValue("username", email);
                        setOtpReadyState(true)
                    }
                })
            } catch (e) {
                notification.error({
                    message: "Error Request OTP",
                    description: e.response?.data?.message || "Something went wrong, please try again later"
                })
            } finally {
                setSubmitting(false)
            }

        }
    }

    return (
        <FormStyled
            name="basic"
            form={form}
            disabled={isSubmiting}
            labelCol={{span: isOtpReady ? 8 : 6}}
            wrapperCol={{span: isOtpReady ? 16 : 18}}
            style={{width: 'minmax(400px, 90%)'}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Typography.Title style={{textAlign: 'center', marginTop: 0, marginBottom: 40}}>Forget
                Password</Typography.Title>
            <Form.Item
                label="Email"
                required
                extra="please enter your email and press Get OTP button"

            >
                <Row gutter={isOtpReady ? 8 : 6}>
                    <Col span={16}>
                        <Form.Item
                            name="username"
                            noStyle
                            rules={[
                                {required: true, message: 'Please input your email!'},
                                {type: 'email', message: 'Please input valid email!'}
                            ]}
                        >
                            <Input placeholder="Enter Email"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button style={{width: '100%'}} loading={isSubmiting}
                                onClick={requestOTP}>{isOtpReady ? "Reset OTP" : "Get OTP"}</Button>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item label="OTP"
                       name="otp"
                       hidden={!isOtpReady}
                       rules={[{required: true, message: 'Please input the otp you got from your email!'}]}>
                <Input placeholder="Enter OTP"/>
            </Form.Item>
            {isOtpReady && <Divider>New Password</Divider>}
            <Form.Item
                label="New Password"
                name="password"
                hidden={!isOtpReady}
                rules={[
                    {
                        required: true,
                        message: 'Please input your new password!',
                    },
                ]}
            >
                <Input.Password placeholder="Enter New Password"/>
            </Form.Item>
            <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                hidden={!isOtpReady}
                rules={[
                    {
                        required: true,
                        message: 'Please input confirm new password!',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Enter New Confirm Password"/>
            </Form.Item>
            <FormAction
                wrapperCol={{
                    offset: isOtpReady ? 8 : 6,
                    span: isOtpReady ? 16 : 18,
                }}
            >
                {isOtpReady && <Button type="primary" htmlType="submit" style={{width: 160}} loading={isSubmiting}>
                    Change Password
                </Button>}
                <Button type="link" onClick={gotoLogin}>
                    Go back to login.
                </Button>
            </FormAction>
        </FormStyled>
    );
};

export default Register;

const FormStyled = styled(Form)`
  min-width: 620px;
  width: 90%;
  max-width: 600px;
  background: #00000088;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
`;
const GoogleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
