import React, {Fragment, useState, useEffect} from 'react';
import styled from "styled-components";
import {App, Button, Divider, Form, Input, Typography, Space, DatePicker, Row, Col, Select} from "antd";
import dayjs from 'dayjs';
import {useLoaderData, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import moment from 'moment';
import {ArrowLeftOutlined} from "@ant-design/icons";

const RFIDAdd = () => {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { TextArea } = Input;
    const [isSubmiting, setSubmitting] = useState();
    const [mode, setMode] = useState();
    const [btnSaveLabel, setBtnSaveLabel] = useState();
    const [headerLabel, setHeaderLabel] = useState();

    const { ocppTagPk } = useParams(); //***
    const {notification, modal} = App.useApp();

    const [inputExpireDate, setInputExpireDate] = useState("");
    const [inputExpiryDate, setInputExpiryDate] = useState("");

    const initForm = useLoaderData();  //***

    const [placeNameOptions, setPlaceNameOptions] = useState();

    useEffect(() => {
        console.log("RFIDAdd : Enter useEffect");
        initAllDropDownList();
        if(ocppTagPk!=null && ocppTagPk!=""){
            console.log("ocppTagPk="+ocppTagPk);
            setMode("edit");
            setBtnSaveLabel("Update");
            setHeaderLabel("Edit RFID Card");
            initFormData();
        }else{
            console.log("ocppTagPk not found");
            setMode("new");
            setBtnSaveLabel("Add");
            setHeaderLabel("New RFID Card");
        }
    }, []);

    const initAllDropDownList = async() =>{
        await cratePlaceMasterList();
    };

    const cratePlaceMasterList = async () => {
        console.log("RFIDAdd : Enter cratePlaceMasterList()");
        try {
            const response = await axios.get("/api/v1/place/all-active");
            if(response!=null && response.data!=null){
                let placeNameOptions = (response.data).map(
                    placeObj => {
                        console.log("- placeName="+placeObj.placeName+", id="+placeObj.id);
                        return {label:placeObj.placeName, value:placeObj.placeName};
                    }
                );
                //console.log("placeNameOptions >> ");
                console.log(placeNameOptions);
                setPlaceNameOptions(placeNameOptions);
            }
        } catch (e) {
            console.log(e);
        }
        console.log("RFIDAdd : Exit cratePlaceMasterList()");
    };

    const initFormData = async () => {
        console.log("RFIDAdd : Enter initFormData");
        try {
            console.log(initForm);
            form.setFieldsValue({
                "idTag": initForm.idTag,
                "parentIdTag": initForm.parentIdTag,
                "placeName": initForm.placeName,
                "roomNo": initForm.roomNo,
                "status": initForm.status,
                "expiryDate": initForm.expiryDateYYYYMMDD,
                //"expiryDate": moment('9999-12-31', 'YYYY-MM-DD'),
                "note": initForm.note,
            })
        } catch (e) {
             console.log(e);
        }
    };

    const onFinish = async (values) => {
        console.log("=== RFIDAdd : onFinish ===");
        console.log(values);

        const registerData = {
            ocppTagPk: ocppTagPk,
            idTag: values.idTag,
            parentIdTag: (values.parentIdTag===undefined)? "" : values.parentIdTag,
            expiryDateStr : inputExpiryDate,
            note: (values.note===undefined)? "" : values.note,
            placeName: (values.placeName===undefined)? "" : values.placeName,
            roomNo: (values.roomNo===undefined)? "" : values.roomNo,
            status: (values.status===undefined)? "" : values.status,
        };
        console.log(registerData);

        try {
            setSubmitting(true);

            if(mode==='new'){
                console.log("mode : new >> /api/v1/rfid/register-v2");
                await axios.post("/api/v1/rfid/register-v2", registerData);

                form.resetFields();

                modal.success({
                    title: 'add new RFID successfully',
                    content: 'your rfid tag is ready',
                })
            }else if(mode==='edit'){
                console.log("mode : edit");
                await axios.put("/api/v1/rfid/update", registerData);
                modal.success({
                    title: 'update RFID successfully',
                    content: 'your rfid tag is ready',
                })
            }
        } catch (e) {
            notification.error({
                message: "Register RFID Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
            console.log("=== RFIDAdd : onFinish : finally ===");
        }
    };

    const onExpireDateChange = (
      value,
      dateString,
    ) => {
      console.log('onExpireDateChange : Selected Time: ', value);
      console.log('onExpireDateChange : Formatted Selected Time: ', dateString);
      setInputExpireDate(dateString);
    };

    const onExpireDateOk = (value) => {
      //console.log('onExpireDateOk : Selected Time: ', value);
    };

    const onChangeExpiryDate = (value, dateString) => {
        //console.log(value, dateString);
        console.log('onChangeExpiryDate : Selected Time: ', value);
        console.log('onChangeExpiryDate : Formatted Selected Time: ', dateString);
        setInputExpiryDate(dateString);
    };
    const dateFormat = "YYYY-MM-DD";

    return (
        <Fragment>
            <Row justify="start" align="middle" gutter={8}>
                <Col style={{ width:'30%' }} ><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></Col>
                <Col style={{ width:'30%' }} align="middle"  ><Typography.Title level={4} style={{ margin: 16 }}>{headerLabel}</Typography.Title></Col>
                <Col style={{ width:'30%' }} ></Col>
            </Row>
            <Row justify="start" align="middle" gutter={8}>
            <Col style={{ width:'30%' }} ></Col>
            <Col style={{ width:'30%' }} >
            <FormStyled
                name="basic"
                form={form}
                disabled={isSubmiting}
                //initialValues={initForm}
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
                style={{width: 'minmax(400px, 90%)'}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Divider>OCPP Tag</Divider>
                <Form.Item
                    label="ID Tag"
                    name="idTag"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your RFID Tag'
                        }
                    ]}
                >
                    <Input placeholder="Enter RFID Tag" maxLength={100} />
                </Form.Item>
                <Form.Item
                    label="Parent ID Tag"
                    name="parentIdTag"
                    rules={[
                        {
                            required: false,
                            message: '-- Empty --',
                        },
                    ]}
                >
                    <Input placeholder ="-- Empty --" maxLength={100} />
                </Form.Item>

                <Form.Item label="Place Name" name="placeName">
                  <Select placeholder="select place name" options={placeNameOptions}>
                  {/*
                    <Select.Option value="">ไม่ระบุ</Select.Option>
                    <Select.Option value="Hotel Demo A001">Hotel Demo A001</Select.Option>
                    <Select.Option value="Cafe Demo Coffee House">Cafe Demo Coffee House</Select.Option>
                   */}
                  </Select>
                </Form.Item>

                <Form.Item
                    label="Room No."
                    name="roomNo"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your room No.'
                        }
                    ]}
                >
                    <Input placeholder="Enter Room No." maxLength={5}/>
                </Form.Item>

                <Form.Item label="Status" name="status">
                  <Select placeholder="select card status">
                    <Select.Option value="A">Active</Select.Option>
                    <Select.Option value="I">Inactive</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                     label="Expiry Date"
                     name="expiryDateYYYYMMDD"
                >
                    <Space direction="vertical" size={12}>
                        {initForm.expiryDateYYYYMMDD}
                        {/* <DatePicker onChange={onChangeExpiryDate} value={moment('2019-01-01', 'YYYY-MM-DD')} /> */}
                        <DatePicker onChange={onChangeExpiryDate} />

                    </Space>
                </Form.Item>
                <Form.Item
                    label="Additional Note"
                    name="note"
                    rules={[
                        {
                            required: false,
                            message: '',
                        },
                    ]}
                >
                    <TextArea rows={4} maxLength={500} />
                </Form.Item>
                <FormAction wrapperCol={{ offset: 7, span: 16 }}>
                            <Space>
                                <Button type="primary" htmlType="submit" style={{width: 120}} loading={isSubmiting}>
                                    {btnSaveLabel}
                                </Button>
                                <Button type="primary" style={{width: 120}} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                        </Space>
                </FormAction>
            </FormStyled>
            </Col>
            <Col style={{ width:'30%' }} ></Col>
         </Row>
        </Fragment>
    );
};

export default RFIDAdd;

const FormStyled = styled(Form)`
  min-width: 620px;
  width: 90%;
  max-width: 600px;
  background: #00000088;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
`;
